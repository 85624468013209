@import "../../global.scss";

.smLinks {
    display: flex;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    overflow-x: visible;
    margin-top: 25px;

    @include smallLandcape {
        margin-top: 0px;
    }
    
    .smIconWrapper {
        overflow-x: visible;
        display: flex;
        align-items: center;
        
        a {
            align-items: center;
            overflow-x: visible;
            color: $mainDarkColor;
            transition: all .2s ease-in-out;
            
            &:hover {
                color: $mainLightColor;
                transform: scale(1.3);
                text-shadow: $mainDarkColor 0 0 9px;
            }
            
            i {
                overflow-x: visible;
                font-size: 1.5em;
            }
        }
    }
}
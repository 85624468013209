$mainDarkColor: #2C0926;
$mainLightColor: antiquewhite;
$mainOrange: #F09700;

$smallTabletWidth: 768px;
$largeMobileWidth: 395px;
$heightSmall: 600px;
$heightSmallLandscape: 400px;
$heightTabletLandscape: 795px;

@mixin mobile {
    @media (max-width: #{$smallTabletWidth}) {
        @content
    }
}

@mixin largemobile {
    @media (max-width: #{$largeMobileWidth}) {
        @content
    }
}

@mixin small {
    @media (max-height: #{$heightSmall}) {
        @content
    }
}

@mixin smallLandcape {
    @media (max-height: #{$heightSmallLandscape}) {
        @content
    }
}

@mixin tabletLandscape {
    @media (max-height: #{$heightTabletLandscape}) and (orientation: landscape) {
        @content
    }
}

.background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: linear-gradient(45deg, $mainOrange 25%, #00000080 25%, #00000080 75%, $mainDarkColor 75%),
    url('../public/assets/faela_audience.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
}

h1 {
    font-size: 50px;

    @include mobile {
        font-size: 30px;
        text-transform: uppercase;
    }

    @include smallLandcape {
        font-size: 25px;
    }
}

h1, h2, h3, h4 {
    text-shadow: #000 0 0 8px;
    color: $mainOrange;
    overflow-x: visible;
}
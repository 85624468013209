@import "../../global.scss";

.music {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include smallLandcape {
        justify-content: flex-start;
    }
    
    h1 {
        text-shadow: #000 0 0 8px;
        color: $mainOrange;
        margin: 20px auto;

        @include smallLandcape {
            margin: 0 auto;
        }
    }

    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        overflow: visible;
        
        @include mobile {
            flex: .8;
            width: 80%;
            margin-top: 30px;
        }

        @include smallLandcape {
            width: 100%;
            flex: 1;
            margin-top: 0;
        }

        .album {
            height: 280px;
            width: 280px;
            border-radius: 5px;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            opacity: 1;
            box-shadow: #00000080 0 0 10px;
            text-shadow: #000 0 0 4px;
            transition: .4s all ease;
            
            @include tabletLandscape {
                height: 220px;
                width: 220px;
                transition: .3s all ease;

                &:hover {
                    height: 250px;
                    width: 250px;
                }
            }

            @include largemobile {
                height: 48vw;
                flex-basis: 60%;
                opacity: 1;

                &:hover {
                    // flex-basis: initial;
                    // height: 80vw;
                    // max-height: 280px;
                    // width: 80vw;
                    // max-width: 280px;
                    z-index: 3;
                    transform: scale(1.6);
                }
            }

            @include smallLandcape {
                height: 15vw;
                width: 15vw;

                &:hover {
                    flex-basis: initial;
                    height: 170px;
                    width: 170px;
                }
            }

            .p2Album {
                padding: 0 3px;
                top: 6px;
                font-weight: bold;
                @include largemobile {
                    font-size: .75rem;
                }
            }

            p {
                top: 32px;
                position: absolute;
                color: $mainLightColor;
                
                @include smallLandcape {
                    top: 27px;
                }
                @include largemobile {
                    font-size: .65rem;
                    top: 22px;
                }
            }

            .playBtnContainer {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                flex-wrap: wrap;
                height: 70%;
                margin-top: 1.3rem;
                transition: all .3s ease-in-out;

                @include largemobile {
                    margin-top: 1.3rem;
                }
                @include smallLandcape {
                    margin-top: 26px;
                }
                
                .playBtn {
                    text-decoration: none;
                    color: $mainOrange;
                    width: 31%;
                    font-size: 20px;
                    text-shadow: #00000080 0 0 3px;
                    padding: 0.4rem 0.2rem;
                    margin: 10px;
                    text-align: center;
                    border-radius: 7px;
                    background-color: #2c0926b3;
                    opacity: 0;
                    box-shadow: #000 0 0 5px;
                    transition: all .3s ease-in-out;
                    
                    @include largemobile {
                        margin: .4rem;
                        width: 34%;
                        font-size: .7rem;
                    }
    
                    .logoSmall {
                        width: 20px;
                        border-radius: 2px;
                        margin: 0 auto;
                        
                        @include largemobile {
                            width: .7rem;
                        }
                    }
    
                    i, .logoSmall {
                        display: block;
                    }
    
                    span {
                        font-size: .8rem;

                        @include largemobile {
                            font-size: .5rem;
                        }
                        @include smallLandcape {
                            display: none;
                        }
                    }
    
                    .fa-spotify {
                        color: #1bd760;
                    }
    
                    .fa-deezer {
                        color: #fff;
                    }
    
                    &:hover {
                        background-color: #f09700d3;
                        color: $mainDarkColor;
    
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            .albumCover {
                opacity: 1;
                transition: all .3s ease-in-out;
            }
            
            &:hover {
                background-color: #000;
                
                .albumCover {
                    opacity: .4;
                    z-index: 0;
                    transition: all .3s ease-in-out;
                }

                .playBtn {
                    opacity: 1;
                    transform: scale(1.1);
                }
            }
        }
    }
}
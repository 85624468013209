@import "../../global.scss";

.concerts {

    .list-group {
        padding: 10px;

        .list-group-item {
            display: block;
            padding: 7px;
            color: $mainLightColor;
            text-decoration: none;
            transition: all .3s ease;
        }
        
        a {
            &:hover {
                color: $mainOrange;
                text-shadow: black 0 0 10px 5px;
            }
        }
    }
}
@import "../../global.scss";

.home {
    display: flex;

    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
         }
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    @include smallLandcape {
        flex-direction: row;
    }

    h1 {
        font-size: 25px;
    }

    .left {
        flex: .5;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .imgContainer {
            width: 600px;
            height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            flex-direction: column;
            text-decoration: none;
            transition: all .5s ease;

            @include smallLandcape {
                height: 80vh;
            }

            @include mobile {
                height: 100%;
            }

            img {
                height: 60%;
                box-shadow: #000 0px 0px 20px 5px;

                @include mobile {
                    height: 80%;
                }

                @include smallLandcape {
                    height: 170px;
                }
            }

            &:hover {
                transform: scale(1.05);
                // background-color: #2c0926b3;
                border-radius: 10px;
            }

            &:hover p {
                color: $mainOrange;
                text-shadow: #000 0 0 8px;
            }
        }

        p {
            padding: 10px;
            font-size: 25px;
            margin: 0 auto;
            color: transparent;
            text-transform: uppercase;
            text-shadow: none;
            transition: all .5s ease;

            @include mobile {
                font-size: 18px;
            }
        }
    }

    .right {
        flex: .5;
        position: relative;

        .homeRightContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;
            color: $mainOrange;
            font-family: 'Secular One', sans-serif;
            text-shadow: black 0 0 12px;

            @include mobile {
                justify-content: space-evenly;
                overflow-x: hidden;
            }

            @include smallLandcape {
                height: 100%;
            }

            h2 {
                font-size: 45px;
                margin: 20px auto;

                @include mobile {
                    font-size: 30px;
                    margin: 0 auto;
                }

                @include smallLandcape {
                    font-size: 25px;
                }
            }

            h3 {
                margin-top: 15%;
                margin-bottom: 8%;
                font-size: 45px;

                @include mobile {
                    margin: 0 auto;
                    font-size: 30px;
                }

                @include smallLandcape {
                    margin-top: 0%;
                    margin-bottom: 5%;
                    font-size: 25px;
                }
                
            }
            
            h4 {
                font-size: 30px;
            }

            .btnMain {
                padding: 10px;
                font-size: 25px;
                border-radius: 20px;
                width: fit-content;
                margin: 0 auto;
                text-decoration: none;
                color: $mainLightColor;
                transition: all .5s ease;
                text-shadow: #000 0 0 8px;

                @include mobile {
                    font-size: 18px;
                    width: 80%;
                }
                
                p {
                    padding: 5px;
                    animation: flash 2s infinite;
                }
                &:hover {
                    transform: scale(1.01);
                    color: $mainOrange;
                    background-color: #2c0926b3;
                    box-shadow: black 0 0 15px;
                    
                    i {
                        color: $mainOrange;
                    }
                }
            }


            @keyframes flash {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        }
    }
}
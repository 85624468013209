@import "../../global.scss";

.live {
    
    h1 {
        text-align: center;
        margin: 20px auto;
    }
    
    .mainContainer {
        text-align: center;
        h2 {
            margin: 20px auto;
            color: $mainOrange;
            font-size: 17px;
        }

        @include mobile {
            padding: 0 10px;
        }
        
        .gigContainer {
            flex-wrap: wrap;
            justify-content: center;
            font-size: 21px;
            display: flex;
            justify-content: center;
            color: $mainLightColor;
            text-shadow: #000 0 0 5px;
            letter-spacing: .6px;
            text-align: justify;

            @include mobile {
                font-size: 17px;
            }

            @include smallLandcape {
                font-size: 17px;
            }
            
            .location {
                margin: 10px auto;
            }
        }
    }
}
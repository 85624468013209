@import "../../global.scss";

.topnav {
    background-color: #2C0926;
    color: #F09700;
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    display: flex;
    z-index: 3;
    transition: all .5s ease;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    .wrapper {
        padding: 5px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .left {
            display: flex;
            align-items: center;
            flex: .6;

            .mainLogo {
                border-radius: 4px;
                padding: 3px 3px 1px;
                transition: all .5s ease;

                .logoImg {
                    width: 110px;
                }

                .logoImgInner {
                    width: 110px;
                    margin-left: -110px;
                    filter: invert(49%) sepia(95%) saturate(1190%) hue-rotate(10deg) brightness(105%) contrast(101%);
                    opacity: 0;
                    transition: all .5s ease;
    
                    &:hover {
                        opacity: 1;
                    }
                }

                &:hover {
                    background-color: $mainDarkColor;
                }
            }


            .menuItem {
                display: flex;
                align-items: center;
                margin: 0 0 0 25px;

                @include mobile {
                    display: none;
                }

                .navLink {
                    color: inherit;
                    text-decoration: none;
                    transition: all .3s ease;

                    &:hover {
                        color: $mainLightColor;
                        text-shadow: $mainDarkColor 0 0 4px;
                    }
                }

                .menuIcon {
                    margin-right: 6px;
                    padding: 2px 3px 0;
                    padding-left: 5px;
                }

                span {
                    font-size: 20px;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding-right: 5px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            flex: .4;
            justify-content: flex-end;

            .hamburger {
                width: 37.5px;
                height: 31px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2px;
                cursor: pointer;
                z-index: 1001;

                span {
                    width: 100%;
                    height: 4px;
                    background-color: $mainOrange;
                    transform-origin: left;
                    transition: all .5s ease;
                }

            }
        }
    }

    &.active {
        background-color: $mainOrange;
        color: $mainDarkColor;

        .hamburger {
            span {

                &:first-child {
                    background-color: $mainDarkColor;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    background-color: $mainDarkColor;
                    transform: rotate(-45deg);
                }
            }
        }

        .logoImgInner {
        opacity: 0 !important;
        }

    }
}
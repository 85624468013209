@import "../../global.scss";

.menu {
    background-color: $mainDarkColor;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 70px;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all .5s ease;
    z-index: 1000;

    @include mobile {
        width: 100%;
        right: -100%;
    }

    &.active {
        right: 0pt;
        background: linear-gradient($mainOrange, #f09700c9);
        backdrop-filter: blur(3px);
    }

    ul {
        margin: 0;
        padding: 0;
        width: 80%;
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
        color: $mainDarkColor;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 70%;
        overflow: visible;

        @include smallLandcape {
            justify-content: flex-start;
        }

        li {
        margin: 15px;
        transition: all .2s ease;
        overflow-x: visible;
        list-style: none;
        
        @include smallLandcape {
            font-size: 20px;
            margin: 7px;
            height: 20px;
        }
        
        @include largemobile {
            font-size: 25px;
            margin: 15px auto;
        }
            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
                overflow-x: visible;
            }

            &:hover {
                transform: scale(1.1);
                color: $mainLightColor;
                text-shadow: $mainDarkColor 0 0 9px;
            }
        }
    }
}
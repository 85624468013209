@import "../../global.scss";

.videos {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin: 20px auto;
        
        @include mobile {
            margin: 10px auto;
        }
    }

    .videoContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex: 1;
        
        .videoWrapper {
            flex: 1 0 46%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1%;

            @include mobile {
                flex: initial;
                align-items: start;
            }

            @include small {
                max-height: 30%;
            }

            @include smallLandcape {
                max-height: initial;
            }

            h3 {
                margin-bottom: 1%;
                z-index: 1;
                align-self: start;
                margin-left: 18%;
                overflow: visible;

                @include mobile {
                    align-self: initial;
                    margin-left: 2%;
                    padding: 0 0 0 5px;
                }
            }
            
            iframe {
                aspect-ratio: 16 / 9;
                width: 100%;
                border-radius: 8px;
                box-shadow: black 0 0 10px;
                
                @include mobile {
                    aspect-ratio: 16 / 9;
                    width: 40%;
                    margin: 0 5px 0 10px;
                }
            }

            .textWrapper {
                width: 65%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                text-shadow: black 0 0 7px;
                overflow: visible;

                @include mobile {
                    flex-direction: row;
                    width: 100vw;
                }
                p {
                    color: $mainLightColor;

                    @include mobile {
                        width: 50%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
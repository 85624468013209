.app {
    height: calc(var(--vh, 1vh) * 100);

    .sections {
        width: 100%;
        height: calc(100% - 70px);
        position: relative;
        top: 70px;
        // scroll-snap-type: y mandatory;
        // scroll-behavior: smooth;

        > * {
            width: 100vw;
            height: 100%;
            // scroll-snap-align: start;
        }
    }
}
@import "../../global.scss";

.about {

    h1 {
        text-align: center;
        margin: 20px auto;
    }
    
    .mainContainer {
        overflow: hidden;
        display: flex;
        justify-content: flex-end;

        @include mobile {
            padding: 10px;
        }

        .textContainer {
            flex: .6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $mainLightColor;
            text-shadow: #000 0 0 5px;
            letter-spacing: .6px;
            text-align: justify;

            @include mobile {
                flex: 1;
            }

            @include largemobile {
                font-size: .9em;
            }

            .aboutText {
                margin: 20px 20px 20px 0;

                @include mobile {
                    margin: 0 auto;
                    padding: 10px;
                }

                .leftCol, .rightCol {
                    display: inline-block;
                    text-align: left;
                    // max-width: 50%;
                }

                .singleLine {
                    display: block;
                    margin: 5px auto;

                    @include mobile {
                        // white-space: initial;
                    }
                }
            }

            .aboutTextTwo {

                @include largemobile {
                    display: none;
                }

            }

            h3 {
                margin: 0 0 20px 0;
                color: $mainOrange;
            }
        }

        .imageContainer {
            flex: .4;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 10px;
            align-items: center;
            justify-content: center;

            @include mobile {
                display: none;
            }
            
            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 40px 10px;
                img {
                    border-radius: 7px;
                    height: 200px;
                    box-shadow: black 0 0 10px;
                }
            }
        }
    }
}
@import "../../global.scss";

.notFoundContainer {
    height: 100%;
    width: 100%;
    
    .wrapper {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .linkPara {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 40px;
            
            @include smallLandcape {
                padding: 20px;
            }

            p {
                margin: 20% auto 5%;
                color: $mainLightColor;
                text-shadow: #000 0 0 5px;
            }
    
            a {
                margin: 0 auto;
                padding: 30px;
                font-size: 35px;
                border-radius: 20px;
                width: fit-content;
                text-decoration: none;
                color: $mainLightColor;
                transition: all .5s ease;
                text-shadow: #000 0 0 8px;
    
                &:hover {
                    transform: scale(1.01);
                    color: $mainOrange;
                    background-color: #2c0926b3;
                    box-shadow: black 0 0 15px;
                }
            }
        }
    }
}
@import "../../global.scss";

.contact {
    
    h1 {
        text-align: center;
        margin: 20px auto;
    }
    
    .mainContainer {
        overflow: hidden;
        display: flex;
        padding: 0 20%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $mainLightColor;
        text-shadow: #000 0 0 5px;
        letter-spacing: .6px;
        text-align: justify;

        @include mobile {
            padding: 0 5%;
        }

        .textContainer {
            flex: .5;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .contactText {
                @include small {
                    font-size: .8em;
                }
            }
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 10px;
            
            input, textarea {
                color: $mainLightColor;
                padding: 0 10px;
                text-shadow: #000 0 0 5px;
                letter-spacing: .6px;
                background-color: rgba(122, 109, 99, 0.5);
                line-height: 30px;
                border-radius: 9px;
                border: 1px solid rgba(220, 132, 0, .3);
                box-shadow: #000 inset 0 0 5px;
                transition: all .3s ease;
                
                &:focus {
                    border: 1px solid $mainOrange;
                }
                &:focus-visible {
                    outline: none;
                    box-shadow: #000 0 0 10px;
                }
            }

            textarea {
                @include mobile {
                    height: 80px;
                }
            }

            input[type=submit] {
                margin-top: 30px !important;
                transform: scale(1.1);
                text-decoration: none;
                border: none;
                color: #F09700;
                width: 31%;
                font-size: 20px;
                text-shadow: #00000080 0 0 3px;
                padding: 8px 4px;
                margin: 10px auto;
                text-align: center;
                border-radius: 7px;
                background-color: #2c0926b3;
                transition: all .3s ease-in-out;
                box-shadow: #000 0 0 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                @include mobile {
                    padding: 0 5%;
                    margin-top: 15px !important;
                }

                @include largemobile {
                    width: 90%;
                }
    
                &:hover {
                    background-color: #f09700d3 !important;
                    color: #2C0926 !important;
                    cursor: pointer;
                }
            }

            label {
                padding: 10px 0 5px;
                color: $mainOrange;
            }

            span {
                margin: 0 auto;
                color: rgb(141, 200, 90);
                transition: all .5s ease;
            }
        }

        a {
            text-decoration: none;
            color: $mainLightColor;
            font-weight: bold;
            text-transform: uppercase;
            transition: all .3s ease;
            overflow: visible;
            margin-bottom: 30px;

            @include largemobile {
                width: 70%;
                text-align: center;
            }

            &:hover {
                color: $mainOrange;
                transform: scale(1.05);
            }
        }
    }
}